import { FormFieldRule } from '../../consts/formField';
import { FormulaCondition, FormulaMethod, FormulaOperator } from '../../consts/formulaOperation';
import { RuleType, ValidationMode } from '../../consts/rule';
import MessageMap from '../i18n.d';

const es419: MessageMap = {
  title: {
    pageLoading: 'Cargando...',
  },
  test: {
    translate: 'Traducir',
    missing: 'Solo opción en español disponible',
  },
  home: {
    title: 'Gestión de reglas',
    buttons: {
      createRule: 'Crear nueva regla',
    },
  },
  ruleCreation: {
    title: 'Creación de reglas',
    toast: {
      fieldsRequiredWarning:
        'Campos obligatorios no completados! Por favor, revise el formulario antes de enviarlo de nuevo.',
    },
    buttons: {
      cancel: 'Cancelar',
      create: 'Crear regla',
    },
    form: {
      ruleName: {
        label: 'Nombre de la regla',
        placeholder: 'Ingrese el nombre de la regla',
        error: 'Se requiere el nombre de la regla.',
      },
      updatedBy: {
        label: 'Modificado por',
      },
      updatedOn: {
        label: 'Modificado el',
      },
      ruleType: {
        label: 'Tipo de regla',
        error: 'Seleccione un tipo de regla.',
        value: {
          [RuleType.Sku]: 'SKU',
          [RuleType.Advertisement]: 'Poster',
          [RuleType.Poster]: 'Poster',
        },
      },
      recognitionModel: {
        label: 'Modelo de reconocimiento',
        tooltip:
          'Cada modelo reconoce un conjunto diferente de elementos. Seleccione el modelo que mejor se adapte a sus necesidades de reglas.',
        select: {
          placeholder: 'Seleccionar modelo',
          infoLabel: 'Todas las fórmulas creadas se descartarán.',
        },
        error: 'Seleccione un modelo de reconocimiento.',
      },
      selectFunction: {
        UNION: {
          placeHolder: 'Unir',
          label1: 'Uniendo',
          label2: ' categorías',
          tooltip: 'Une todas los artículos dentro de las categorías seleccionadas.',
        },
        INTERSECT: {
          placeHolder: 'Intersección',
          label1: 'intersección de',
          label2: ' categorías',
          tooltip:
            'Une los artículos que pertenecen simultáneamente a todas las categorías seleccionadas.',
        },
      },
      formulaSection: {
        title: 'Fórmula',
        subtitle: 'La regla seguirá esta fórmula.',
        addOperation: 'Añadir operación',
        tooltip: 'Completa los campos arriba para editar la fórmula.',
      },
      formulaOperation: {
        selectMethod: {
          label: 'Método',
          placeholder: 'Seleccionar método',
          error: 'Seleccione un método.',
          value: {
            [FormulaMethod.OperationCount]: 'Cantidad',
            [FormulaMethod.OperationShare]: 'Porcentaje',
          },
        },
        selectItem: {
          label: 'Artículo',
          placeholder: 'Seleccionar elemento',
          selectingItem: 'Seleccionando...',
          selected: {
            singular: '{quantity} seleccionado',
            plural: '{quantity} seleccionados',
          },
          error: 'Seleccione algunos elementos.',
          options: {
            SKU: '{companyName} y patrocinador SKUs',
            CATEGORIES: "{companyName} y patrocinador {ruleType}'s categorias",
            POSTER: '{companyName} y patrocinador posters',
            COMPETITION: "Otras compañías {ruleType}'s",
          },
          text: {
            SKU: 'Los patrocinadores son empresas que promueven tareas en BEES Force.',
            CATEGORIES: "{ruleType}'s se puede seleccionar individualmente o por categorías.",
            POSTER: 'Los patrocinadores son empresas que promueven tareas en BEES Force.',
            COMPETITION: 'Socios, vendedores y otras empresas.',
          },
        },
        selectOperator: {
          label: 'Operador',
          placeholder: 'Seleccionar operador',
          error: 'Seleccione un operador.',
          value: {
            [FormulaOperator.EqualsTo]: 'Igual a',
            [FormulaOperator.GreaterThan]: 'Mas grande que',
            [FormulaOperator.GreaterOrEqualsTo]: 'Mas grande o igual a',
            [FormulaOperator.LessThan]: 'Menos que',
            [FormulaOperator.LessOrEqualsTo]: 'Menos o igual a',
          },
        },
        value: {
          label: 'Valor',
          placeholder: 'Ejemplo: 5',
          error: 'Ingrese un valor.',
          errorMaxShareAllowed: 'De 0 a 100.',
        },
        selectCondition: {
          placeholder: 'Seleccionar condición',
          error: 'Seleccione una condición.',
          value: {
            [FormulaCondition.Or]: 'O',
            [FormulaCondition.And]: 'Y',
          },
        },
      },
    },
    modal: {
      changeCountryOrVendor: {
        buttonSecondaryLabel: 'No, volver',
        buttonPrimaryLabel: 'Sí, cambiar',
        description: 'Todas las fórmulas creadas se descartarán.',
        title: {
          vendor: '¿Cambiar proveedor?',
          country: '¿Cambiar país?',
        },
      },
      [FormFieldRule.RecognitionModelId]: {
        buttonSecondaryLabel: 'No, volver',
        buttonPrimaryLabel: 'Sí, cambiar',
        description: 'Todas las fórmulas creadas se descartarán.',
        title: '¿Cambiar el modelo de reconocimiento?',
      },
      [FormFieldRule.Type]: {
        buttonSecondaryLabel: 'No, volver',
        buttonPrimaryLabel: 'Sí, cambiar',
        description: 'Todas las fórmulas creadas se descartarán.',
        title: '¿Cambiar el tipo de regla?',
      },
      deleteOperation: {
        buttonSecondaryLabel: 'No, volver',
        buttonPrimaryLabel: 'Sí, eliminar',
        description: 'La operación creada se descartará.',
        title: '¿Eliminar operación?',
      },
    },
    drawer: {
      selectLabels: {
        toast: {
          labelsAdded: {
            singular: '{quantity} item añadido',
            plural: '{quantity} itens añadidos',
          },
        },
        checkbox: {
          selectAll: 'Nombre',
        },
        placeholder: {
          search: 'Buscar elementos',
        },
        title: {
          SKU: 'Seleccione SKUs',
          CATEGORIES: 'Seleccione categorías',
          POSTER: 'Seleccione poster',
        },
        clearSelection: 'Anular selección',
        buttons: {
          addItems: {
            SKU: 'Agregar SKUs',
            CATEGORIES: 'Agregar categorías',
            POSTER: 'Agregar poster',
          },
          cancel: 'Cancelar',
          confirm: 'Finalizar selección',
        },
        unit: {
          singular: '{quantity} unidad | {labelName}',
          plural: '{quantity} unidades | {labelName}',
        },
        noSearchResults: {
          title: 'No se encontraron resultados',
          subtitle: 'Verifica la ortografía o prueba usar otras palabras clave.',
        },
        results: 'resultados',
        nestedDrawer: {
          selectedLabels: {
            unit: {
              singular: '{quantity} unidad | {labelName}',
              plural: '{quantity} unidades | {labelName}',
            },
            title: {
              SKU: 'SKUs seleccionados',
              ADVERTISEMENT: 'Poster seleccionados',
            },
            subtitle: {
              quantity: {
                SKU: '{quantity} SKUs seleccionados',
                ADVERTISEMENT: '{quantity} posters seleccionados',
              },
              noResultsFound: {
                title: 'No se encontraron resultados',
                subtitle: 'Verifique las categorías o pruebe con otras diferentes.',
              },
            },
            button: {
              removeAll: 'Eliminar todo',
            },
            confirmCategories: {
              title: '¿Sobrescribir categorías?',
              subtitle: 'Las categorías seleccionadas se sobrescribirán.',
              button: {
                cancel: 'Cancelar',
                confirm: 'Sobrescribir',
              },
            },
          },
        },
        message: {
          error: {
            default: {
              title: 'Algo salió mal.',
              subtitle: 'Inténtelo de nuevo más tarde',
            },
          },
        },
      },
    },
  },
  ruleEdit: {
    title: 'Edición de regla',
    buttons: {
      cancel: 'Cancelar',
      update: 'Guardar cambios',
    },
    modal: {
      changeRuleName: {
        buttonSecondaryLabel: 'Cancelar',
        buttonPrimaryLabel: 'Guardar cambios',
        description: 'La información de la regla fue editada. ¿Desea guardar los cambios?',
        title: '¿Guardar cambios?',
      },
      changeRuleFormula: {
        buttonSecondaryLabel: 'Cancelar',
        buttonPrimaryLabel: 'Guardar cambios',
        description:
          'La fórmula de la regla será editada. Las verificaciones anteriores no se verán afectadas por el cambio, solo las futuras.',
        info: 'Si desea reprocesar verificaciones anteriores, comuníquese con nuestro equipo a través de un ticket.',
        title: '¿Guardar cambios?',
      },
    },
  },
  ruleList: {
    table: {
      header: {
        id: 'ID',
        name: 'Nombre',
        targetType: 'Tipo de objetivo',
        validationMode: 'Modo de validación',
        updatedBy: 'Modificado por',
        updatedAt: 'Modificado el',
        actions: 'Acciones',
      },
      fields: {
        id: {
          hover: 'Copiar código de identificación',
        },
        validationMode: {
          [ValidationMode.Prediction]: 'Basado en reglas',
          [ValidationMode.Collection]: 'Validar más tarde',
          [ValidationMode.AutoFinishedJob]: 'Validar automáticamente',
        },
        actions: {
          viewAndEdit: 'Ver y editar',
        },
      },
      pagination: {
        pageSize: 'Mostrar {pageSize} elementos por página',
      },
      emptyMessage: 'No hay información para mostrar.',
      errorMessage: 'Ooops! Algo salió mal',
      buttonTryAgain: 'Inténtalo de nuevo',
      emptyDescription: 'Verifica la ortografía o intenta con una búsqueda diferente',
      emptyTitle: 'No se encontraron resultados',
    },
    search: {
      placeholder: 'Buscar por nombre, ID o labels',
    },
    filter: {
      button: {
        label: 'Filtros',
        clearFilters: 'Limpiar filtros',
        cancel: 'Cancelar',
        apply: 'Aplicar',
      },
      modifiedDate: {
        label: 'Modificado el',
        placeholder: 'Seleccionar rango de fechas',
        separator: ' al ',
      },
      modifiedOn: 'Modificado el',
      type: 'Tipo de objetivo',
    },
  },
  toast: {
    info: {
      copyRuleId: 'RuleId copiado!',
      copy: 'copiar',
    },
    success: {
      request: 'Regla creada con éxito.',
      update: 'Regla rule ID {keyId} actualizada con éxito.',
    },
    error: {
      request: 'Ocurrió un error con su solicitud. Por favor, inténtelo de nuevo más tarde.',
    },
  },
};

export default es419;
