import { FormFieldRule } from '../../consts/formField';
import { FormulaCondition, FormulaMethod, FormulaOperator } from '../../consts/formulaOperation';
import { RuleType, ValidationMode } from '../../consts/rule';
import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  title: {
    pageLoading: 'Carregando...',
  },
  test: {
    translate: 'Traduzir',
    missing: 'Disponível apenas a opção em Português',
  },
  home: {
    title: 'Gerenciamento de regras',
    buttons: {
      createRule: 'Criar nova regra',
    },
  },
  ruleCreation: {
    title: 'Criação de regra',
    toast: {
      fieldsRequiredWarning:
        'Campos obrigatórios não preenchidos! Verifique o formulário antes de enviar novamente.',
    },
    buttons: {
      cancel: 'Cancelar',
      create: 'Criar regra',
    },
    form: {
      ruleName: {
        label: 'Nome da regra',
        placeholder: 'Inserir o nome da regra',
        error: 'O nome da regra é obrigatório.',
      },
      updatedBy: {
        label: 'Alterado por',
      },
      updatedOn: {
        label: 'Alterado em',
      },
      ruleType: {
        label: 'Tipo de regra',
        error: 'Selecione um tipo de regra.',
        value: {
          [RuleType.Sku]: 'SKU',
          [RuleType.Advertisement]: 'Poster',
          [RuleType.Poster]: 'Poster',
        },
      },
      recognitionModel: {
        label: 'Modelo de reconhecimento',
        tooltip:
          'Cada modelo reconhece um conjunto diferente de itens. Selecione o modelo que melhor se adapta às suas necessidades de regra.',
        select: {
          placeholder: 'Selecionar modelo',
          infoLabel: 'Todas as fórmulas criadas serão descartadas.',
        },
        error: 'Selecione um modelo de reconhecimento.',
      },
      selectFunction: {
        UNION: {
          placeHolder: 'União',
          label1: 'Unindo',
          label2: ' categorias',
          tooltip: 'Une todos os itens dentro das categorias selecionadas.',
        },
        INTERSECT: {
          placeHolder: 'Intersecção',
          label1: 'Intersecção de',
          label2: ' categorias',
          tooltip: 'Une os itens que pertencem simultaneamente a todas as categorias selecionadas.',
        },
      },
      formulaSection: {
        title: 'Fórmula',
        subtitle: 'A regra seguirá esta fórmula.',
        addOperation: 'Adicionar operação',
        tooltip: 'Complete os campos acima para editar a fórmula.',
      },
      formulaOperation: {
        selectMethod: {
          label: 'Método',
          placeholder: 'Selecione um método',
          error: 'Selecione um método.',
          value: {
            [FormulaMethod.OperationCount]: 'Quantidade',
            [FormulaMethod.OperationShare]: 'Porcentagem',
          },
        },
        selectItem: {
          label: 'Item',
          placeholder: 'Selecionar itens',
          selectingItem: 'Selecionando...',
          selected: {
            singular: '{quantity} selecionado',
            plural: '{quantity} selecionados',
          },
          error: 'Selecione alguns itens.',
          options: {
            SKU: '{companyName} e SKUs patrocinados',
            CATEGORIES: "{companyName} e {ruleType}'s categorias patrocinadas",
            POSTER: '{companyName} e posters patrocinados',
            COMPETITION: "Outras empresas {ruleType}'s",
          },
          text: {
            SKU: 'Patrocinadores são empresas que promovem tarefas no BEES Force.',
            CATEGORIES: "{ruleType}'s podem ser selecionados individualmente ou por categorias.",
            POSTER: 'Patrocinadores são empresas que promovem tarefas no BEES Force.',
            COMPETITION: 'Parceiros, vendedores e outras empresas.',
          },
        },
        selectOperator: {
          label: 'Operador',
          placeholder: 'Selecione o operador',
          error: 'Selecione um operador.',
          value: {
            [FormulaOperator.EqualsTo]: 'Igual a',
            [FormulaOperator.GreaterThan]: 'Maior que',
            [FormulaOperator.GreaterOrEqualsTo]: 'Maior ou igual a',
            [FormulaOperator.LessThan]: 'Menor que',
            [FormulaOperator.LessOrEqualsTo]: 'Menor ou igual a',
          },
        },
        value: {
          label: 'Valor',
          placeholder: 'Exemplo: 5',
          error: 'Insira um valor.',
          errorMaxShareAllowed: 'De 0 a 100.',
        },
        selectCondition: {
          placeholder: 'Selecionar condição',
          error: 'Selecione uma condição.',
          value: {
            [FormulaCondition.Or]: 'OU',
            [FormulaCondition.And]: 'E',
          },
        },
      },
    },
    modal: {
      changeCountryOrVendor: {
        buttonSecondaryLabel: 'Não, voltar',
        buttonPrimaryLabel: 'Sim, alterar',
        description: 'Todas as fórmulas criadas serão descartadas.',
        title: {
          vendor: 'Alterar fornecedor?',
          country: 'Alterar país?',
        },
      },
      [FormFieldRule.RecognitionModelId]: {
        buttonSecondaryLabel: 'Não, voltar',
        buttonPrimaryLabel: 'Sim, alterar',
        description: 'Todas as fórmulas criadas serão descartadas.',
        title: 'Alterar modelo de reconhecimento?',
      },
      [FormFieldRule.Type]: {
        buttonSecondaryLabel: 'Não, voltar',
        buttonPrimaryLabel: 'Sim, alterar',
        description: 'Todas as fórmulas criadas serão descartadas.',
        title: 'Alterar tipo de regra?',
      },
      deleteOperation: {
        buttonSecondaryLabel: 'Não, voltar',
        buttonPrimaryLabel: 'Sim, excluir',
        description: 'A operação criada será descartada.',
        title: 'Excluir operação?',
      },
    },
    drawer: {
      selectLabels: {
        toast: {
          labelsAdded: {
            singular: '{quantity} item adicionado',
            plural: '{quantity} itens adicionados',
          },
        },
        checkbox: {
          selectAll: 'Nome',
        },
        placeholder: {
          search: 'Buscar itens',
        },
        title: {
          SKU: 'Selecione SKUs',
          CATEGORIES: 'Selecione categorias',
          POSTER: 'Selecione poster',
        },
        clearSelection: 'Desmarcar',
        buttons: {
          addItems: {
            SKU: 'Adicionar SKUs',
            CATEGORIES: 'Adicionar categorias',
            POSTER: 'Adicionar poster',
          },
          cancel: 'Cancelar',
          confirm: 'Finalizar seleção',
        },
        unit: {
          singular: '{quantity} unidade | {labelName}',
          plural: '{quantity} unidades | {labelName}',
        },
        noSearchResults: {
          title: 'Nenhum resultado encontrado',
          subtitle: 'Verifique a ortografia ou tente palavras-chave diferentes.',
        },
        results: 'resultados',
        nestedDrawer: {
          selectedLabels: {
            unit: {
              singular: '{quantity} unidade | {labelName}',
              plural: '{quantity} unidades | {labelName}',
            },
            title: {
              SKU: 'SKUs selecionados',
              ADVERTISEMENT: 'Poster selecionados',
            },
            subtitle: {
              quantity: {
                SKU: '{quantity} SKUs selecionados',
                ADVERTISEMENT: '{quantity} poster selecionados',
              },
              noResultsFound: {
                title: 'Nenhum resultado encontrado',
                subtitle: 'Verifique as categorias ou tente outras.',
              },
            },
            button: {
              removeAll: 'Remover todos',
            },
            confirmCategories: {
              title: 'Substituir categorias?',
              subtitle: 'As categorias selecionadas serão substituídas.',
              button: {
                cancel: 'Cancelar',
                confirm: 'Substituir',
              },
            },
          },
        },
        message: {
          error: {
            default: {
              title: 'Algo deu errado.',
              subtitle: 'Tente novamente mais tarde.',
            },
          },
        },
      },
    },
  },
  ruleEdit: {
    title: 'Editar regra',
    buttons: {
      cancel: 'Cancelar',
      update: 'Salvar alterações',
    },
    modal: {
      changeRuleName: {
        buttonSecondaryLabel: 'Cancelar',
        buttonPrimaryLabel: 'Salvar alterações',
        description: 'As informações da regra foram editadas. Deseja salvar as alterações?',
        title: 'Salvar alterações?',
      },
      changeRuleFormula: {
        buttonSecondaryLabel: 'Cancelar',
        buttonPrimaryLabel: 'Salvar alterações',
        description:
          'A fórmula da regra foi editada. As verificações anteriores não serão afetadas por essa mudança, apenas as futuras.',
        info: 'Se desejar reprocesar verificações anteriores, entre em contato com nossa equipe por meio de um ticket.',
        title: 'Salvar alterações?',
      },
    },
  },
  ruleList: {
    table: {
      header: {
        id: 'Código',
        name: 'Nome',
        targetType: 'Tipo de objetivo',
        validationMode: 'Tipo de validação',
        updatedBy: 'Alterado por',
        updatedAt: 'Alterado em',
        actions: 'Ações',
      },
      fields: {
        id: {
          hover: 'Copiar código',
        },
        validationMode: {
          [ValidationMode.Prediction]: 'Baseado em regras',
          [ValidationMode.Collection]: 'Validar mais tarde',
          [ValidationMode.AutoFinishedJob]: 'Validar automaticamente',
        },
        actions: {
          viewAndEdit: 'Ver e editar',
        },
      },
      pagination: {
        pageSize: 'Mostrar {pageSize} itens por página',
      },
      emptyMessage: 'Não há informações para exibir.',
      errorMessage: 'Ooops! Algo deu errado',
      buttonTryAgain: 'Tente novamente',
      emptyDescription: 'Verifique a ortografia ou tente uma pesquisa diferente',
      emptyTitle: 'Nenhum resultado encontrado',
    },
    search: {
      placeholder: 'Pesquisar por nome da regra, código ou labels',
    },
    filter: {
      button: {
        label: 'Filtros',
        clearFilters: 'Limpar filtros',
        cancel: 'Cancelar',
        apply: 'Aplicar',
      },
      modifiedDate: {
        label: 'Alterado em',
        placeholder: 'Selecione o período',
        separator: ' a ',
      },
      modifiedOn: 'Alterado em',
      type: 'Tipo de objetivo',
    },
  },
  toast: {
    info: {
      copyRuleId: 'RuleId copiado!',
      copy: 'copiar',
    },
    success: {
      request: 'Regra criada com sucesso.',
      update: 'Regra rule ID {keyId} atualizada com sucesso.',
    },
    error: {
      request: 'Ocorreu um erro com sua solicitação. Por favor, tente novamente mais tarde.',
    },
  },
};

export default ptBR;
