import { FormFieldRule } from '../../consts/formField';
import { FormulaCondition, FormulaMethod, FormulaOperator } from '../../consts/formulaOperation';
import { RuleType, ValidationMode } from '../../consts/rule';
import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  title: {
    pageLoading: 'Loading...',
  },
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },
  home: {
    title: 'Rule management',
    buttons: {
      createRule: 'Create new rule',
    },
  },
  ruleCreation: {
    title: 'Rule creation',
    toast: {
      fieldsRequiredWarning:
        'Required fields not filed! Please, check the form before submitting again.',
    },
    buttons: {
      cancel: 'Cancel',
      create: 'Create rule',
    },
    form: {
      ruleName: {
        label: 'Rule name',
        placeholder: 'Enter the rule name',
        error: 'Rule name is required.',
      },
      updatedBy: {
        label: 'Modified by',
      },
      updatedOn: {
        label: 'Modified on',
      },
      ruleType: {
        label: 'Rule type',
        error: 'Select a rule type.',
        value: {
          [RuleType.Sku]: 'SKU',
          [RuleType.Advertisement]: 'Poster',
          [RuleType.Poster]: 'Poster',
        },
      },
      recognitionModel: {
        label: 'Recognition model',
        tooltip:
          'Each model recognizes a different set of items.  Select the model that best suits your rule needs.',
        select: {
          placeholder: 'Select model',
          infoLabel: 'All formulas created will be discarded.',
        },
        error: 'Select a recognition model.',
      },
      selectFunction: {
        UNION: {
          placeHolder: 'Unite',
          label1: 'Uniting  ',
          label2: ' categories',
          tooltip: 'Unites all items within the selected categories.',
        },
        INTERSECT: {
          placeHolder: 'Intersect',
          label1: 'Intersecting',
          label2: ' categories',
          tooltip: 'Unites the items that belong simultaneously to all the categories selected.',
        },
      },
      formulaSection: {
        title: 'Formula',
        subtitle: 'The rule will follow this formula.',
        addOperation: 'Add operation',
        tooltip: 'Complete the fields above to edit the formula.',
      },
      formulaOperation: {
        selectMethod: {
          label: 'Method',
          placeholder: 'Select method',
          error: 'Select a method.',
          value: {
            [FormulaMethod.OperationCount]: 'Quantity',
            [FormulaMethod.OperationShare]: 'Percentage',
          },
        },
        selectItem: {
          label: 'Item',
          placeholder: 'Select items',
          selectingItem: 'Selecting...',
          selected: {
            singular: '{quantity} selected',
            plural: '{quantity} selected',
          },
          error: 'Select some items.',
          options: {
            SKU: "{companyName} and sponsor's SKUs",
            CATEGORIES: "{companyName} and sponsor's {ruleType}'s categories",
            POSTER: "{companyName} and sponsor's posters",
            COMPETITION: "Other companies {ruleType}'s",
          },
          text: {
            SKU: 'Sponsors are companies that promote tasks on BEES Force.',
            CATEGORIES: "{ruleType}'s can be selected individually or per categories.",
            POSTER: 'Sponsors are companies that promete tasks on BEES Force.',
            COMPETITION: 'Partners, sellers, and other companies.',
          },
        },
        selectOperator: {
          label: 'Operator',
          placeholder: 'Select operator',
          error: 'Select an operator.',
          value: {
            [FormulaOperator.EqualsTo]: 'Equals to',
            [FormulaOperator.GreaterThan]: 'Greater than',
            [FormulaOperator.GreaterOrEqualsTo]: 'Greater or equals to',
            [FormulaOperator.LessThan]: 'Less than',
            [FormulaOperator.LessOrEqualsTo]: 'Less or equals to',
          },
        },
        value: {
          label: 'Value',
          placeholder: 'Example: 5',
          error: 'Enter a value.',
          errorMaxShareAllowed: 'From 0 to 100.',
        },
        selectCondition: {
          placeholder: 'Select condition',
          error: 'Select a condition.',
          value: {
            [FormulaCondition.Or]: 'OR',
            [FormulaCondition.And]: 'AND',
          },
        },
      },
    },
    modal: {
      changeCountryOrVendor: {
        buttonSecondaryLabel: 'No, go back',
        buttonPrimaryLabel: 'Yes, change',
        description: 'All formulas created will be discarted.',
        title: {
          vendor: 'Change vendor?',
          country: 'Change country?',
        },
      },
      [FormFieldRule.RecognitionModelId]: {
        buttonSecondaryLabel: 'No, go back',
        buttonPrimaryLabel: 'Yes, change',
        description: 'All formulas created will be discarted.',
        title: 'Change recognition model?',
      },
      [FormFieldRule.Type]: {
        buttonSecondaryLabel: 'No, go back',
        buttonPrimaryLabel: 'Yes, change',
        description: 'All formulas created will be discarted.',
        title: 'Change rule type?',
      },
      deleteOperation: {
        buttonSecondaryLabel: 'No, go back',
        buttonPrimaryLabel: 'Yes, delete',
        description: 'The operation created will be discarded.',
        title: 'Delete operation?',
      },
    },
    drawer: {
      selectLabels: {
        toast: {
          labelsAdded: {
            singular: '{quantity} item added',
            plural: '{quantity} items added',
          },
        },
        checkbox: {
          selectAll: 'Name',
        },
        placeholder: {
          search: 'Search items',
        },
        title: {
          SKU: 'Select SKUs',
          CATEGORIES: 'Select categories',
          POSTER: 'Select poster',
        },
        clearSelection: 'Deselect',
        buttons: {
          addItems: {
            SKU: 'Add SKUs',
            CATEGORIES: 'Add categories',
            POSTER: 'Add poster',
          },
          cancel: 'Cancel',
          confirm: 'Finish selection',
        },
        unit: {
          singular: '{quantity} unit | {labelName}',
          plural: '{quantity} units | {labelName}',
        },
        noSearchResults: {
          title: 'No results found',
          subtitle: 'Check the spelling or try different keywords.',
        },
        results: 'results',
        nestedDrawer: {
          selectedLabels: {
            unit: {
              singular: '{quantity} unit | {labelName}',
              plural: '{quantity} units | {labelName}',
            },
            title: {
              SKU: 'Selected SKUs',
              ADVERTISEMENT: 'Selected posters',
            },
            subtitle: {
              quantity: {
                SKU: '{quantity} SKUs selected',
                ADVERTISEMENT: '{quantity} posters selected',
              },
              noResultsFound: {
                title: 'No results found',
                subtitle: 'Check the categories or try different ones.',
              },
            },
            button: {
              removeAll: 'Remove all',
            },
            confirmCategories: {
              title: 'Overwrite categories?',
              subtitle: 'The selected categories will be overwritten.',
              button: {
                cancel: 'Cancel',
                confirm: 'Overwrite',
              },
            },
          },
        },
        message: {
          error: {
            default: {
              title: 'Something went wrong.',
              subtitle: 'Try again later',
            },
          },
        },
      },
    },
  },
  ruleEdit: {
    title: 'Edit rule',
    buttons: {
      cancel: 'Cancel',
      update: 'Save Changes',
    },
    modal: {
      changeRuleName: {
        buttonSecondaryLabel: 'Cancel',
        buttonPrimaryLabel: 'Save changes',
        description: 'The rule information was edited. Do you want to save the changes?',
        title: 'Save changes?',
      },
      changeRuleFormula: {
        buttonSecondaryLabel: 'Cancel',
        buttonPrimaryLabel: 'Save',
        description:
          'The rule formula will be edited. Previous verifications will not be affected by the change, only future ones.',
        info: "If you'd like to reprocess previous verifications, reach out to our team through a ticket.",
        title: 'Save changes?',
      },
    },
  },
  ruleList: {
    table: {
      header: {
        id: 'ID',
        name: 'Name',
        targetType: 'Target type',
        validationMode: 'Validation mode',
        updatedBy: 'Modified by',
        updatedAt: 'Modified on',
        actions: 'Actions',
      },
      fields: {
        id: {
          hover: 'Copy ID',
        },
        validationMode: {
          [ValidationMode.Prediction]: 'Rule-based',
          [ValidationMode.Collection]: 'Validate later',
          [ValidationMode.AutoFinishedJob]: 'Automatically validate',
        },
        actions: {
          viewAndEdit: 'View and edit',
        },
      },
      pagination: {
        pageSize: 'Show {pageSize} items per page',
      },
      emptyMessage: 'There is no information to show.',
      errorMessage: 'Ooops! Something went wrong',
      buttonTryAgain: 'Try again',
      emptyDescription: 'Check the spelling or try a different search',
      emptyTitle: 'No results found',
    },
    search: {
      placeholder: 'Search by rule name, ID or labels',
    },
    filter: {
      button: {
        label: 'Filter',
        clearFilters: 'Clear filters',
        cancel: 'Cancel',
        apply: 'Apply',
      },
      modifiedDate: {
        label: 'Modified date',
        placeholder: 'Select date range',
        separator: ' to ',
      },
      modifiedOn: 'Modified on',
      type: 'Target type',
      validationMode: 'Validation mode',
    },
  },
  toast: {
    info: {
      copyRuleId: 'RuleId copied!',
      copy: 'copy',
    },
    success: {
      request: 'Rule created successfully.',
      update: 'The rule ID {keyId} was successfully edited.',
    },
    error: {
      request: 'An error occurred with your request. Please, try again later.',
    },
  },
};

export default enUS;
